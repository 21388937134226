// Import images for electronic products
import pillar2panelstandingdecors from '../../../assets/products/services/4 pillar 2 panel standing decors/4000.png';
import pillarflowerdecor from '../../../assets/products/services/4 pillar flower decor/12000.png';
import ACgeneralservices from '../../../assets/products/services/AC general services/500.png';
import Receptionbackdropdesign from '../../../assets/products/services/Reception backdrop design/20000.jpeg';

const Servicesproducts = [
  { id: 1, image: pillar2panelstandingdecors, name: '4 Pillar 2 Panel Standing Decors', price: '₹4,000', category: 'Decoration' },
  { id: 2, image: pillarflowerdecor, name: '4 Pillar Flower Decor', price: '₹12,000', category: 'Decoration' },
  { id: 3, image: ACgeneralservices, name: 'AC General Services', price: '₹500', category: 'AC Services' },
  { id: 4, image: Receptionbackdropdesign, name: 'Reception Backdrop Design', price: '₹20,000', category: 'Decoration' }
];

export default Servicesproducts;
